.country-selector-search {
  width: 100%;
}

body {
  background-color: #fff !important;
}
.custom-progress-bar {
  background-image: none !important;
  background-color: #FF9603 !important;
}
.custom-progress-error-bar{
  display: none;
}
.custom-toast-body {
  border-radius: 5px !important;
  margin-top: 19px !important;
  margin-right: -15px !important;
}
.custom-toast-error-body{
  margin-top: 19px !important;
  background-color: #F5DFE0 !important;
  width: 450px;
  margin-left: -120px;
}
.custom-toast-error{
  width: 400px !important;
}
.modal.jodit_fullsize_box {
  position: absolute !important;
}
.modal.jodit_fullsize_box .jodit_workplace {
  height: calc(100vh - 56px) !important;
}

.overlayClassName {
  z-index: 1000 !important;
}

.send_vox_wrapper {
  top: 45px !important;
}

.opacity-change.show {
  opacity: 0.2 !important;
}

@media (max-width: 768px) {
  .custom-toast-body {
    margin: 20px 10px !important;
    width: 70% !important;
    float: right !important;
  }
}

.tippy-tooltip {
  border-radius: 10px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 6px 20px 0 rgba(0, 0, 0, 0.12);
  padding: 15px !important;
}
.dark-theme {
  background: #858C9A !important;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25) !important;
  top: -5px !important;
}
.dark-theme .arrow-regular {
  border-top: 7px solid #858C9A !important;
}
.dark-theme .tippy-tooltip-content {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #F8F8F8;
}

.tippy-tooltip-content .estimate_charges {
  line-height: 19px;
}

.light-theme .tippy-tooltip-content {
  font-family: lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: black;
}

.tippy-tooltip.custom-theme {
  background-color: white;
  color: #2e394f;
  font-family: lato, sans-serif;
  font-size: 14px;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 6px 20px 0 rgba(0, 0, 0, 0.12);
}
/* For SVG arrow (Round) */
.tippy-popper[x-placement^=right] .tippy-tooltip.custom-theme .tippy-roundarrow { fill: #ffffff; }
.tippy-popper[x-placement^=left] .tippy-tooltip.custom-theme .tippy-roundarrow { fill: #ffffff; }
.tippy-popper[x-placement^=top] .tippy-tooltip.custom-theme .tippy-roundarrow { fill: #ffffff; }
.tippy-popper[x-placement^=bottom] .tippy-tooltip.custom-theme .tippy-roundarrow { fill: #ffffff; }
.tooltip-span{
  color: #2e394f;
  text-align: left;
  display: block;
  padding-top: 10px;
}
